<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8">
              <div align="center">
                {{ yyyymmddDisp }}の出欠訂正({{ studentName }})
              </div>
            </v-col>
            <v-col cols="4">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="374"
        color="#fff"
      >

        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider
                name="出欠登録"
                rules="required"
                v-slot="{ errors }"
                :skipIfEmpty="false"
              >
                <v-select
                    label="出欠登録"
                    item-text="label"
                    item-value="syukketuKbn"
                    v-model="selectedSyukketu"
                    prepend-icon="$cet_required"
                    :items="syukketu"
                    :error-messages="errors[0]"
                    outlined
                    hide-details="auto"
                  ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="備考" rules="required|max:150" v-slot="{ errors }">
                <v-text-field
                  v-model="biko"
                  prepend-icon="$cet_required"
                  label="備考"
                  placeholder="備考"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="150"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="コメント" rules="max:500" v-slot="{ errors }">
                <v-text-field
                  v-model="comment"
                  label="コメント"
                  placeholder="コメント"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="500"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" class="black--text" @click="toBackPage(groupName,groupId)" width="49%" 
          >戻る
          </v-btn
          >
          <v-btn color="accent" class="black--text" @click="syukketuSave(groupName,groupId)"
           :disabled="pastLockFlg ? pastLockFlg : invalid" width="49%" 
          >更新
          </v-btn
          >
        </v-card-actions>
        <v-card
            class="d-flex justify-center flex-column mx-auto my-0 flat"
            width="1200"
            color="#fff"
          >
            <v-list>
              <v-subheader>【履歴】</v-subheader>
              <v-list-item-group  v-if="history.length">
                <v-list-item v-for="row in history" :key="row.postDateTime">
                  <v-list-item-content>
                    <v-list-item-subtitle>更新日時：{{ row.postDate }}</v-list-item-subtitle>
                    <v-list-item-subtitle>更新者：{{ row.userNm }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="row.syukketuNm">出欠区分：{{ row.syukketuNm }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.biko">備考：{{ row.biko }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.comment">コメント：{{ row.comment }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-list-item-group  v-if="!history.length" color="primary">
                <v-list-item>履歴はありません</v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>
  
  <script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';

export default {
  props: ['studentId', 'studentName', 'groupName', 'groupId', 'beforeName', 'param_yyyymmdd'],
  watch: {
    yyyymmdd: async function(newVal, oldVal) {
      console.log(newVal, oldVal)
      await this.reload();
    }
  },
  data() {
    return {
      yyyymmdd: "",
      yyyymmddDisp: "",
      selectedSyukketu: { label: "", syukketuKbn: 0 }, //初期値
      syukketu: [
        { label: "", syukketuKbn: 0 },
        { label: "欠席", syukketuKbn: 1 },
        { label: "遅刻", syukketuKbn: 5 },
      ],
      biko: "",
      comment: "",
      history: [],
      language:{
        language: 'Japanese',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        yearSuffix: '年',
        ymd: true,
      },
      pastLockFlg: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    await this.reload();
  },
  methods: {
    // データピッカー開閉
    calendar() {
        if(!this.$refs.picker.isOpen) {
            this.$refs.picker.$el.querySelector("input").focus();
            this.$refs.picker.showCalendar();
        } else {
            this.$refs.picker.close();
        }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
        this.yyyymmdd = format(selectedDate, 'yyyy-MM-dd', { locale: ja });
        this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // ページ再読込
    async reload() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "loadAttendanceEdit",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.selectedSyukketu = res.data.syukketuKbn;
        this.biko = res.data.biko;
        this.history = res.data.history;
        this.pastLockFlg = res.data.pastLockFlg;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });

      // ローディング終了
      this.$root.loading = false;
    },
    // 出欠情報設定保存
    async syukketuSave(groupName,groupId) {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveAttendanceEdit",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId,
        syukketuKbn: this.selectedSyukketu,
        biko: this.biko,
        comment: this.comment,
      };

      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        alert("登録しました");
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      var params = {};
      if (this.beforeName) {
        params = {
          name: this.beforeName,
          params: {
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      } else {
        params = {
          name: "UserList",
          params: {
            groupName: groupName,
            groupId: groupId,
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      }
      this.$router.push(params);
    },
    toBackPage(groupName,groupId){
      console.log("戻る",groupId,groupName);
      var params = {};
      if (this.beforeName) {
        params = {
          name: this.beforeName,
          params: {
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      } else {
        params = {
          name: "UserList",
          params: {
            groupName: groupName,
            groupId: groupId,
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      }
      this.$router.push(params);
    }
  },
};
</script>


<style scoped>
.wrap-text{
  white-space: normal;
}
</style>