<template>
  <v-container fluid>
    <v-row dense justify="center">
      <v-col cols="6">
        <div align="left">
          <a @click="toAttendancePastView" class="text-decoration-none">過去出欠確認</a>
        </div>
      </v-col>
      <v-col cols="6">
        <div align="right">
          <a @click="toAttendanceFutureRegist" class="text-decoration-none">明日以降の出欠連絡</a>
        </div>
      </v-col>
    </v-row>
    <v-row dense justify="center">
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        width="374"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
          {{ yyyymmddDisp }}の出欠連絡
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider
                name="出欠登録"
                rules="required"
                v-slot="{ errors }"
                :skipIfEmpty="false"
              >
                <v-select
                  label="出欠登録"
                  item-text="label"
                  item-value="syukketuKbn"
                  prepend-icon="$cet_required"
                  v-model="selectedSyukketu"
                  :items="syukketu"
                  :error-messages="errors[0]"
                  outlined
                  hide-details="auto"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="備考" rules="required|max:150" v-slot="{ errors }">
                <v-text-field
                  v-model="biko"
                  prepend-icon="$cet_required"
                  label="備考"
                  placeholder="備考"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="150"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense v-if="selectedSyukketu !== 1">
            <v-col dense cols="12" justify="center">
              <v-checkbox
                class="mt-0"
                v-model="sotaiFlg"
                label="早退を予定している"
                hide-details="auto" />
            </v-col>
          </v-row>
          <v-row dense v-if="sotaiFlg">
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="早退事由" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                  v-model="leavingReason"
                  prepend-icon="$cet_required"
                  label="早退事由"
                  placeholder="早退事由"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="50"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense v-if="sotaiFlg">
            <v-col dense cols="12" justify="center">
              
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="leavingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider name="下校開始予定時刻" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="leavingTime"
                    prepend-icon="$cet_required"
                    label="下校開始予定時刻"
                    placeholder="下校開始予定時刻"
                    :error-messages="errors[0]"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="leavingTime"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedStep"
                  full-width
                  @click:minute="$refs.menu.save(leavingTime)"
                  ></v-time-picker>
                </v-menu>
            </v-col>
          </v-row>
          <!--<v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="コメント" rules="max:500" v-slot="{ errors }">
                <v-text-field
                  v-model="comment"
                  label="コメント"
                  :error-messages="errors[0]"
                  placeholder="コメント"
                  outlined
                  dense
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>-->
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent" class="black--text" @click="syukketuSave" :disabled="invalid" width="100%">
            連絡する
          </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-left pt-0 pb-0" white-space="pre" v-if="history.length">
            <div v-if="readSchoolFlg">学校確認状況：確認済</div>
            <div v-else>学校確認状況：未確認</div>
        </v-card-text>
        <v-card-text class="d-flex justify-left pt-0 pb-0 ml-7" white-space="pre" v-if="gatePassageTime">
            <div>早退時刻：{{ gatePassageTime }} に通用門を通過しました</div>
        </v-card-text>
        <v-card
            class="d-flex justify-center flex-column mx-auto my-0 flat"
            width="1200"
            color="#fff"
          >
            <v-list>
              <v-subheader>【履歴】</v-subheader>
              <v-list-item-group  v-if="history.length">
                <v-list-item v-for="row in history" :key="row.postDateTime">
                  <v-list-item-content>
                    <v-list-item-subtitle>更新日時：{{ row.postDate }}</v-list-item-subtitle>
                    <v-list-item-subtitle>更新者：{{ row.userNm }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="row.syukketuNm">出欠区分：{{ row.syukketuNm }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.biko">備考：{{ row.biko }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.comment">コメント：{{ row.comment }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-list-item-group  v-if="!history.length" color="primary">
                <v-list-item>履歴はありません</v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
      </v-card>
    </ValidationObserver>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['id'],
  watch: {
    selectedSyukketu: function(newVal, oldVal) {
      if(newVal !== oldVal && newVal == 1){
        this.sotaiFlg = false;
      }
    }
  },
  data() {
    return {
      selectedSyukketu: { label: "", syukketuKbn: 0 }, //初期値
      syukketu: [
        { label: "", syukketuKbn: 0 },
        { label: "欠席", syukketuKbn: 1 },
        { label: "遅刻", syukketuKbn: 5 },
      ],
      yyyymmdd: "",
      yyyymmddDisp: "",
      biko: "",
      comment: "",
      readSchoolFlg: false,
      history: [],
      gatePassageTime: "",
      existsFlg: false,
      sotaiFlg: false,
      leavingReason: "",
      leavingTime: "",
      menu2: false,
      modal2: false,
    };

  },
  // 初期表示時データ読み込み
  async created() {
    await this.reload();
  },

  methods: {
    // タイムピッカーの入力制限設定
    allowedHours: v => 5 < v && v < 24,
    allowedStep: m => m % 10 === 0,
    // 画面初期表示
    async reload() {
      
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

      const data = {
        funcName: "loadAttendanceRegist",
        studentId : this.id
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.yyyymmdd = res.data.yyyymmdd;
        this.yyyymmddDisp = res.data.yyyymmddDisp;
        this.selectedSyukketu = res.data.syukketuKbn;
        this.biko = res.data.biko;
        this.comment = "";
        this.sotaiFlg = res.data.sotaiFlg;
        this.leavingReason = res.data.leavingReason;
        this.leavingTime = res.data.leavingTime;
        this.history = res.data.history;
        this.gatePassageTime = res.data.gatePassageTime;
        this.readSchoolFlg = res.data.readSchoolFlg;
        this.existsFlg = res.data.existsFlg;
      }).catch((e) =>{
        this.error = e.message;
        this.$root.loading = false;
      });

      // ローディング終了
      this.$root.loading = false;
    },
    // プロフィール設定保存
    async syukketuSave() {

      if(this.existsFlg == false && this.selectedSyukketu == 0 && this.sotaiFlg == false) {
        alert("出席の場合は登録して頂かなくても大丈夫です。");
        return;
      }

      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveAttendanceRegist",
        studentId : this.id,
        yyyymmdd: this.yyyymmdd,
        syukketuKbn: this.selectedSyukketu,
        biko: this.biko,
        sotaiFlg: this.sotaiFlg,
        leavingReason: this.leavingReason,
        leavingTime: this.leavingTime,
        comment: this.comment,
      };

      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        alert("連絡しました。");
      }).catch((e) =>{
        this.error = e.message;
        this.$root.loading = false;
      });
      await this.reload();
    },
    // 出欠事前連絡へ遷移
    toAttendanceFutureRegist() {
      this.$router.push({
        name: "AttendanceFutureRegist",
        params: {
          id: this.id,
        }
      });
    },
    // 出欠履歴確認へ遷移
    toAttendancePastView() {
      this.$router.push({
        name: "AttendancePastView",
        params: {
          id: this.id,
        }
      });
    },
  },

};
</script>

<style scoped>
.wrap-text{
  white-space: normal;
}
</style>